<template>
    <b-form-group class="">
        <app-label>{{label}}</app-label>
            <b-form-input
                ref="textInput"
                :value="value"
                @input="setValues($event)"
                :type="type"
                :placeholder="placeholder"
                :state="errors.length?false:null" 
                :disabled="disabled"
                @keyup.enter="()=>$emit('onEnter')"
                @keyup="()=>$emit('onKeyup')"
                @keypress="keyPress($event)"
                @blur="()=>$emit('onBlur')"
            />
            <small class="text-danger" v-if="errors.length>0" >{{ errors[0] }}</small>
    </b-form-group>
</template>
<script>
import {
    BFormInput, BFormGroup
} from 'bootstrap-vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
export default {
    components: {
        BFormInput,
        BFormGroup,
        AppLabel,
    },
    props: {
        value:[String],
        type:{
            type:[String],
            default:'text'
        },
        name:[String],
        autofocus:{
            type:[Boolean],
            default:false
        },
        placeholder:[String],
        label:[String],
        errors:{
            type:[Array],
            default:()=>[]
        },
        disabled:{
            type:[Boolean],
            default:false
        }
    },
    methods:{
        setValues(event){
            this.$emit('input', event)
        },
        focusInput(){
            this.$refs.textInput.focus()
        },
        keyPress(event){
            this.$emit('onKeydown',event)
        }
    }

}
</script>
<style>
</style>