<template>
    <div>
        <b-modal ref="createModal" v-model="show" size="md" centered hide-header hide-footer hide-header-close :no-close-on-backdrop="loading">
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Create Folder</app-heading-1>
                    <app-normal-text>Create folder for your new project</app-normal-text>
                </div>
                <div class="my-2">
                    <div class="row">
                        <div class="col-12">
                            <app-text-input label="Folder name " v-model="folder_data.name" placeholder="Enter Name"></app-text-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-text-input label="Email Address" ref="suggestionInput" v-model="collaborator_details.email" placeholder="Enter Emails" @onEnter="addCollaborator(collaborator_details)" @onKeyup="fetchCollaburators"></app-text-input>
                            <div class="sugestion-list" v-if="!hide_suggestions">
                                <div class="suggestion " v-for="(s,index) in suggestions" :key="index" @click="selectSuggetion(s)">{{s.email}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1" v-for="(c,index) in collaborators_list" :key="index">
                        <div class="col-12 d-flex justify-content-between">
                            <app-normal-text >{{c}}</app-normal-text>
                            <feather-icon icon="XIcon" size="22" class="cursor-pointer" @click="removeCollabraotor(index)"/>
                        </div>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" :disabled="loading" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading" :disabled="!folder_data.name.length"  @click="createFolder()">Create</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import {search_user_by_email, create_folder} from "@/apis/others/documents"
import { BModal, VBModal } from 'bootstrap-vue'
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSimpleButton,
        BModal,
        VBModal,
        AppNormalText,
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            folder_data:{
                name:'',
                collaborators:[]
            },
            collaborator_email:'',
            collaborator_details:{
                email:'',
                id:'',
            },
            loading:false,
            errorMessage: '',
            hide_suggestions:true,
            suggestions:[],
            collaborators_list:[],
            focus:false
        }
    },
    methods:{
        showModal(){
            this.show = true
            this.folder_data.name = ''
            this.folder_data.collaborators = []
            this.collaborator_details.email =''
            this.collaborator_details.id = ''
            this.collaborators_list=[]
            this.hide_suggestions=true
            // this.$refs.createModal.show()
        },
        hideModal(){
            this.show = false
            this.$refs.createModal.hide()
        },
        moveNext(){
            this.$refs.createModal.hide()
        },
        addCollaborator(collaborator){
            this.collaborators_list.push(collaborator.email)
            this.folder_data.collaborators.push(collaborator.id)
            this.collaborator_details.email = ''
            this.collaborator_details.id = ''
        },
        removeCollabraotor(index){
            this.folder_data.collaborators.splice(index,1)
            this.collaborators_list.splice(index,1)
        },
        fetchCollaburators(){
            if(this.collaborator_details.email.length>0){
                search_user_by_email(this.collaborator_details.email).then(resp=>{
                    this.suggestions=resp.data.users
                })
                this.hide_suggestions=false
            }else{
                this.hide_suggestions=true
            }
            
        },
        selectSuggetion(s){
            this.collaborator_details.email=s.email
            this.collaborator_details.id=s._id
            this.suggestions=[]
            this.hide_suggestions=true
            this.$refs.suggestionInput.focusInput()
        },
        createFolder(){
            this.loading = true
            create_folder(this.folder_data).then(resp=>{
                this.loading = false
                this.errorMessage = ''
                this.show = false
                this.folder_data.name = ''
                this.folder_data.collaborators = []
                this.collaborator_details.email = ''
                this.collaborator_details.id = ''
                this.$emit('next')
            })
        }
    },
}
</script>
<style>
.sugestion-list{
    max-height: 100px;
    width: 95%;
    border:1px solid #ecf2ff;
    overflow-y: auto;
    margin-bottom: 8px;
    z-index: 1000;
    position:absolute;
    background-color: #ffffff;
}
.suggestion{
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.suggestion:hover{
    background-color: #ecf2ff;
}

.sugestion-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sugestion-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(226, 225, 225);
    border-radius: 10px;
  }
  
  /* Handle */
  .sugestion-list::-webkit-scrollbar-thumb {
    background: #ecf2ff;
    border-radius: 5px;
  }
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
</style>