<template>
  <div class="navbar-container d-flex justify-content-between align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <img class="d-block d-md-none mr-auto" src="@/assets/images/logo/dashboard-logo.svg" alt="">
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center mr-auto d-none d-lg-flex">
     
      <!-- <feather-icon icon="ChevronLeftIcon" size="26"  @click="$router.push(backLink)"></feather-icon> -->
      <feather-icon v-if="$route.name=='documents-folder'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="()=>$router.push('/documents')"></feather-icon>
      <feather-icon v-if="$route.name=='documents-subfolder'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="goToLink()"></feather-icon>
      <feather-icon v-if="$route.name=='invoice-details'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="()=>$router.push(`/invoices`)"></feather-icon>
      <app-heading-1>{{$route.meta.title=='Folder'?$route.params.folder:$route.meta.title=='SubFolder'?$route.params.subFolder:$route.meta.title=='Invoice Details'? `Invoice # : ${$route.params.invoice}`:$route.meta.title}}</app-heading-1>
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center">
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown /> -->
      <div class="d-md-flex d-none  align-items-center">
        <app-simple-button  variant="outline-primary" class="nowrap d-none" v-if="$route.name=='documents'|| $route.name=='documents-folder' || $route.name=='documents-subfolder'" @click="()=>$refs.uploadFile.showModal()">Upload Documents</app-simple-button>
        <app-simple-button class="d-none" variant="primary" v-if="$route.meta.title=='Manage Users'" @click="()=>$refs.createUser.showModal()">Create New User</app-simple-button>
        <app-simple-button  variant="primary" class="ml-1 nowrap d-none" v-if="$route.name=='documents'" @click="()=>$refs.createfolder.showModal()">Create Folder</app-simple-button>
        <app-simple-button variant="primary" class="ml-1 nowrap d-none" v-if="$route.name=='documents-folder'" @click="()=>$refs.createsubfolder.showModal()">Create Sub Folder</app-simple-button>
        <app-simple-button variant="primary" v-if="$route.name=='invoices'&& getUserRole()!='client'" @click="()=>$refs.invoiceUpload.showModal()">Upload Invoice</app-simple-button>
        <app-simple-button variant="primary" class="ml-1 nowrap d-none" v-if="$route.meta.title=='Reports'" @click="()=>$refs.generateReport.showModal()">Generate Report</app-simple-button>
      </div>
      
      <div class="d-flex  align-items-center ">
        
        <div class="ml-1"><feather-icon v-if="getUserRole()!='admin'"   size="26" icon="HelpCircleIcon" class="cursor-pointer" @click="()=>$refs.support.showModal()"></feather-icon></div>
        <notification-dropdown />
        <user-dropdown />
      </div>
      <create-user ref="createUser"/>
      <invoice-upload ref="invoiceUpload" />
      <upload-file ref="uploadFile" />
      <create-folder ref="createfolder" @next="onFolderCreated()"/>
      <create-sub-folder ref="createsubfolder" @next="onFolderCreated()"/>
      <generate-report ref="generateReport"/>
      <support ref="support"/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,BDropdown, BDropdownItem, BButton
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import CreateUser from '@/components/functional-components/admin/users/CreateUser.vue'
import InvoiceUpload from '@/components/functional-components/other/invoice/InvoiceUpload.vue'
import UploadFile from '@/components/functional-components/other/documents/UploadFile.vue'
import CreateFolder from '@/components/functional-components/other/documents/CreateFolder.vue'
import CreateSubFolder from '@/components/functional-components/other/documents/CreateSubFolder.vue'
import GenerateReport from '@/components/functional-components/admin/reports/GenerateReport.vue'
import Support from '@/components/functional-components/other/Support.vue'
import {getUserData} from '@/auth/utils'
import { eventBus } from '@/utils/eventbus'
export default {
  components: {
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    AppHeading1,
    AppSimpleButton,
    CreateUser,
    InvoiceUpload,
    UploadFile,
    CreateFolder,
    CreateSubFolder,
    GenerateReport,
    Support
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed:{
    backLink(){
      let link=''
      if(this.$route.meta.title=='Folder'){
        link='/documents'
      }else if(this.$route.meta.title=='SubFolder'){
        link=`/documents/${this.$route.params.folder}`
      }else{
        link
      }
    }
  },
  methods: {
    goToLink(){
      this.$router.push(`/documents/${this.$route.params.folder}/${this.$route.params.folderId}`)
    },
    getUserRole(){
      const user=getUserData()
      return user.role
    },
    onFolderCreated(){
      eventBus.$emit('on-folder-created')
    }
  },
}
</script>
<style scoped>
.title-heading{
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.nowrap{
  white-space: nowrap;
}
</style>
