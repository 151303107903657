import http from "@/utils/http";
export async function create_signature(data){
    const req = (await http.post(`/user/signature`,data));
    return req.data; 
}
export async function get_signature(id){
    const req = (await http.get(`/user/signature`));
    return req.data; 
}


export async function submit_query(data) {
    const req = (await http.post('/support-queries',data));
    return req.data;   
}