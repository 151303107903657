<template>
    <b-form-group>
        <div class="d-flex justify-content-between ">
          <app-label >{{label}}</app-label>
        </div>
          <b-input-group
            class="input-group-merge "
            :class="[errors.length? 'is-invalid':null, focus?'amount-input-field':'']"
          >
          <b-input-group-append is-text class="amount-sign" >
            <feather-icon
              class="cursor-pointer"
              icon="DollarSignIcon"
            />
          </b-input-group-append>
            <b-form-input   
              :value="value"
              type="number"
              :state="errors.length?false:null"
              class="form-control-merge" 
              :placeholder="placeholder"
              @input="setValues($event)"
              @keypress="validateAmount"
              @focus="()=>focus=true"
              @blur="()=>focus=false"
            />

           
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>

</template>
<script>
import {
    BFormInput, BInputGroup, BFormGroup, BInputGroupAppend
} from 'bootstrap-vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'     
    export default {
        components:{
          BFormInput,
          BFormGroup,
          BInputGroup,
          BInputGroupAppend,
          AppLabel
        },
        props:{
            label:[String],
            placeholder:[String],
            value:[String, Number],
            forgetPasswordLink:[String],
            errors:{
              type:[Array],
              default:()=>[]
            }
        },
        data(){
            return {
                fieldType:'password',
                password:'',
                focus:false,
            }
        },
        methods:{
            setValues(value){
                this.$emit('input', value)
            },
            validateAmount(e){
              const controlKeys = [8, 9, 37, 39, 46];

    // Allow control keys to be processed
    if (!controlKeys.includes(e.keyCode)) {
        // Get the character entered
        let char = String.fromCharCode(e.keyCode);

        // Get the current value of the input
        let input = e.target.value;

        // If the user is entering a number or a decimal point
        if (/^(?!.*%).*[0-9.]+$/.test(char)) {
            // Append the character to the current value
            let newValue = input + char;

            // Test the new value against the regex pattern
            let regex = /^\d{1,100}(\.\d{1,2})?$/;

            // If the new value does not match the pattern, prevent the default action
            if (!regex.test(newValue)) {
                e.preventDefault();
            }
        } else {
            // If the character is not a digit or a decimal point, prevent the default action
            e.preventDefault();
        }
      }
            }
        }
    }
</script>
<style scoped>
.input-group-append .input-group-text{
    border-top-left-radius:5px !important;
    border-bottom-left-radius:5px !important;
    border-left:1px solid #d8d6de !important;
}
.amount-input-field{
  box-shadow: -2px 0px 0px 0px #2968ff !important;
  border-radius: 5px !important;
}
</style>