<template>
    <div>
        <b-modal ref="createModal" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1>Add New User</app-heading-1>
                </div>
                <div class="mt-2">
                    <validation-observer ref="createUserForm" >

                        <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                            <app-text-input label="Email Address" v-model="user_data.email" placeholder="Enter your email address" :errors="errors" />
                        </validation-provider>

                        <validation-provider #default="{ errors }" name="Role" vid="role" rules="required">
                            <app-select-box label="Select Role" v-model="user_data.role" :options="options" :errors="errors"></app-select-box>
                        </validation-provider>

                        <app-text-input label="Company" v-model="user_data.companyName" placeholder="Enter" />
                    </validation-observer>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary"  @click="addUser">Add</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import {create_user} from "@/apis/admin/users"
import { BModal, VBModal } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSelectBox,
        AppSimpleButton,
        BModal,
        VBModal,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            user_data:{
                email:'',
                companyName:'',
                role:null
            },
            options: [
                { value: null, text: 'Select' },
                { value: 'client', text: 'Client' },
                { value: 'vendor', text: 'Vendor' },
                { value: 'service_provider', text: 'Service Provider' },
                // { value: 'broker', text: 'Broker'},
            ],
            required, 
            email,
            loading:false,
            errorMessage: ''
        }
    },
    methods:{
        showModal(){
            this.$refs.createModal.show()
        },
        hideModal(){
            this.$refs.createModal.hide()
        },
        addUser(){
            this.errorMessage = '';
            this.$refs.createUserForm.validate().then(success=>{
                if(!success){
                    this.errorMessage='Please fill all the required fields & enter valid data'
                    return
                }
                this.loading=true
                create_user(this.user_data).then(resp=>{
                    this.show = false
                    this.loading=false
                    this.$bvToast.toast(resp.message, {
                        title: 'Success',
                        variant: 'success',
                        solid: true,
                    })
                }).catch(err=>{
                    this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    })
                    this.loading=false
                })
            })
        }
    },
}
</script>